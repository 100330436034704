




















































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import DrawerComponent from '@/mixins/drawer-component';
import ParkingFacilityService from './service/parking-facility';
import { dateFormat } from '@/filter';
import { SubSystemType } from '@/model/enum';

@Component
export default class ParkingFacilityDetailDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    parkingFacilityModel: any = {};
    AttributeTableColumns: Array<any> = [
        {
            title: '名称',
            dataIndex: 'name'
        },
        {
            title: '数值',
            dataIndex: 'value'
        },
        {
            title: '更新时间',
            dataIndex: 'lastUpdateTs',
            customRender: (text, record, index) => {
                return dateFormat(text);
            }
        }
    ];

    drawerDetailOpen(id: string, title: string, subSystemName: SubSystemType) {
        this.drawerOpen(id, title);
        ParkingFacilityService.parkingFacilityDetail(id, subSystemName).then(res => {
            this.parkingFacilityModel = res;
        });
    }
}

