var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.drawerTitle))
      ]),
      _c(
        "jtl-card",
        { attrs: { title: "基本信息", bordered: false } },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "" } },
            [
              _c("a-descriptions-item", { attrs: { label: "设备编号" } }, [
                _vm._v(
                  " " + _vm._s(_vm.parkingFacilityModel.facilityCode) + " "
                )
              ]),
              _c("a-descriptions-item", { attrs: { label: "设备名称" } }, [
                _vm._v(" " + _vm._s(_vm.parkingFacilityModel.name) + " ")
              ]),
              _c("a-descriptions-item", { attrs: { label: "设备类型" } }, [
                _vm._v(" " + _vm._s(_vm.parkingFacilityModel.typeName) + " ")
              ]),
              _c("a-descriptions-item", { attrs: { label: "安装位置" } }, [
                _vm._v(
                  " " + _vm._s(_vm.parkingFacilityModel.locationName) + " "
                )
              ]),
              _c("a-descriptions-item", { attrs: { label: "出厂编号" } }, [
                _vm._v(" " + _vm._s(_vm.parkingFacilityModel.code) + " ")
              ]),
              _c("a-descriptions-item", { attrs: { label: "启用状态" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("entityStatusFilter")(
                        _vm.parkingFacilityModel.enabled
                      )
                    ) +
                    " "
                )
              ]),
              _c("a-descriptions-item", { attrs: { label: "创建时间" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("dateFormat")(_vm.parkingFacilityModel.createdTime)
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c(
        "jtl-card",
        { attrs: { title: "属性信息", bordered: false } },
        [
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.AttributeTableColumns,
              "data-source": _vm.parkingFacilityModel.props,
              pagination: false,
              scroll: _vm.tableScroll,
              "row-key": "uuid",
              size: _vm.TableSize
            },
            on: { change: _vm.tableChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }