import { SubSystemQueryModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import ParkingFacilityService from '../service/parking-facility';
import { entityStatusList } from '@/filter/entity-status';

export class ParkingFacilityQueryModel extends SubSystemQueryModel {
    @QueryControl({
        label: '设备类型',
        type: QueryControlType.SELECT,
        optionsPromise: ParkingFacilityService.getFacilityType,
        optionsPromiseParam: 'subSystemType',
        hasAllOption: true,
        span: 6
    })
    type: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '启用状态',
        type: QueryControlType.SELECT,
        options: entityStatusList,
        hasAllOption: true,
        span: 6
    })
    status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    name: string = undefined;

    // @QueryControl({
    //     label: '系统类型',
    //     type: QueryControlType.SELECT,
    //     optionsPromise: ParkingFacilityService.getFacilityType,
    //     optionsPromiseParam: 'subSystemType',
    //     hasAllOption: true,
    //     span: 6
    // })
    // systemType: string = JTL.CONSTANT.ALL_OPTION.value;

    locationIds: Array<string> = undefined;

    toService() {
        const data = {
            params: {
                locationIds: this.locationIds,
                enable: this.status,
                name: this.name,
                typeId: this.type
            }

        };
        return data;
    }
}
