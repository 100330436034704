import { Component } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import { SubSystemType } from '@/model/enum';

@Component
export default class SubSystemComponent extends BaseComponent {
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.isFirstLoad = true;
            vm.initPage();
        });
    }
    get SubSystemName(): SubSystemType {
        return this.$route.meta.type as SubSystemType;
    }
}
